import React from 'react'
import { graphql } from 'gatsby'
import {
  Layout,
  Container,
  SEO,
  PageTitle,
  CardPost,
  Row,
} from 'components/common'

export default props => {
  console.log(props)
  const {
    data,
    pageContext: { slug },
  } = props

  return (
    <Layout>
      <Container>
        <SEO type="Organization" title={slug} location={`/${slug}`} />
        <Row>
          <PageTitle>Articles related to {slug}</PageTitle>
          {data &&
            data.allWordpressPost &&
            data.allWordpressPost.edges.map(
              ({
                node: {
                  id,
                  excerpt,
                  timeToRead,
                  title,
                  slug,
                  categories,
                  date,
                  featured_media,
                  fields: {
                    readingTime: { text },
                  },
                },
              }) => (
                <CardPost
                  key={id}
                  description={excerpt.replace(/<(.|\n)*?>/g, '')}
                  timeToRead={text}
                  title={title}
                  date={date}
                  path={'/' + slug}
                  thumbnail={
                    featured_media
                      ? featured_media.localFile.childImageSharp.fluid
                          .originalImg
                      : null
                  }
                  tags={categories}
                />
              )
            )}
        </Row>
      </Container>
    </Layout>
  )
}

export const postQuery = graphql`
  query($slug: String!) {
    allWordpressPost(
      filter: {
        status: { eq: "publish" }
        categories: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          id
          slug
          status
          title
          excerpt
          date(formatString: "MMMM DD, YYYY")
          modified(formatString: "MMMM DD, YYYY")
          fields {
            readingTime {
              text
              minutes
              time
              words
            }
          }
          categories {
            id
            name
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 700) {
                  originalImg
                }
              }
            }
          }
        }
      }
    }
  }
`
